<template>
  <c-card title="Daftar">
    <template slot="action">
      <b-button v-if="cP(4)" :to="{name:'users-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
    </template>
    <pagination-table ref="table" endpoint="v1/users/index" :fields="fields">
      <template v-slot:last_login="{item:{last_login}}">
        {{last_login | fulldate}}
      </template>
      <template v-slot:id="{item}">
        <b-button-group size="sm">
          <b-button v-if="cP(7)" variant="primary" :to="{name:'users-show',params:{id:item.id}}"><feather-icon icon="EyeIcon"></feather-icon></b-button>
          <b-button v-if="cP(5)" variant="primary" :to="{name:'users-edit',params:{id:item.id}}"><feather-icon icon="EditIcon"></feather-icon></b-button>
          <b-button v-if="cP(6)" :disabled="item.id==auth.id" variant="danger" @click="confirmDelete(item)"><feather-icon icon="TrashIcon"></feather-icon></b-button>
        </b-button-group>
      </template>
    </pagination-table>
  </c-card>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'
import {BButtonGroup,BButton} from 'bootstrap-vue'

export default {
  components:{
    CCard,
    PaginationTable,
    BButtonGroup,
    BButton
  },
  data(){
    const fields = [
      {key:'name',label:'Nama Lengkap',sortable:true},
      {key:'username',label:'Username',sortable:true},
      {key:'group_name',label:'Jabatan',sortable:true},
      {key:'last_login',label:'Login Terakhir',sortable:true},
      {key:'status',label:'Status',sortable:true},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    return {
      fields
    }
  },
  methods:{
    confirmDelete(item){
      // this.confirmation(`submitDelete`,`Anda akan menghapus User <b>${item.name}</b>`,`Apakah Anda Yakin ?`,{id:item.id,code:item.name})
      this.confirmation(`submitDelete`,{text:`Anda akan menghapus User <b>${item.name}</b>`,parameter:{id:item.id,code:item.name}})
    },
    async submitDelete({id,code}){
      try {
        await this.$http.delete(`v1/users/delete/`,{params:{id,code}})
        this.notify(`Data Berhasil Dihapus`,`OK`)
        this.$refs.table.refresh()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>